.footer {
    text-align: center;
    padding: 15px 15px;
    background-color: #131e22;
    color: rgb(207, 207, 207);
}

.footer h2 {
    margin: 0px;
    font-size: 1em;
    font-weight: 400;
}

@media only screen and (max-width: 680px) {
    .footer h2 {
        font-size: 0.8em;
    }
}