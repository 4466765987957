.projects {
    padding: 20px 0px 30px 0px;
    margin: 0px;

    background-color: #131e22;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects h1 {
    text-align: center;
    /* background-color: green; */
    margin: 40px 0px 0px 0px;
    /* text-shadow: 0px 4px 10px #000000be; */
    font-size: 2.5em;
    color: white;
}

@media only screen and (max-width: 1000px) {   
    .projects h1 {
        margin: 20px 0px 0px 0px;
    }
}

@media only screen and (max-width: 680px) {
    .projects h1 {
        margin: 10px 0px 0px 0px;
        font-size: 1.8em;
    }
}