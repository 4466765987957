.experience {
    background-image: url('../../Assets/whistler.jpg');
    background-position: center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 60px;
}

.experience h1 {
    margin: 40px 0px 20px 0px;
    font-size: 2.5em;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
}

.experience .card {
    background-color: #131e22;
    max-width: 1100px;
    padding: 20px 40px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.experience .card h2 {
    font-size: 1.5em;
    margin: 10px 0px;
}

.experience .card a {
    text-decoration: none;
    color: skyblue;
}

.experience .card a:hover {
    color: rgb(165, 224, 248);
}

.experience .card .timerange {
    color: orange;
    font-style: italic;
    margin-top: 5px;
}

.experience .card .card-body {
    line-height: 1.6;
    font-size: 1.2em;
}

@media only screen and (max-width: 680px) {
    .experience h1 {
        font-size: 1.8em;
    }
}