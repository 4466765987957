@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html { 
    font-family: 'Roboto Mono', monospace;
}
.header {
    margin: 0;
    padding: 0;
    background-image: url(/static/media/burrard_bridge_dark.a439ab2f.jpg);
    background-size: 1920px;
    background-position: 50% 65%;
    color: white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    height: 800px;
    position: relative;
}

.header .name {
    align-self: center;
    text-align: center;
    text-shadow: 0px 2px 20px #000000;
    
}

.header h1 {
    font-size: 5em;
    margin: 0;
    /* background-image: radial-gradient(rgba(0, 0, 0, .4) 0%, rgba(0,0,0,0) 100%); */
}

.header h2 {
    font-size: 2em;
    margin: 0;
    /* background-image: radial-gradient(rgba(0, 0, 0, 1) 0%, rgba(0,0,0,0) 100%); */
}

.header .icons {
    display: flex;
    justify-content: space-between;
    align-self: center;
    /* background-image: radial-gradient(rgba(0, 0, 0, 0.450) 0%, rgba(0,0,0,0) 100%); */
}

.header .icons a {
    font-size: 1.8em;
    margin: 20px 10px;
    color: white;
}

.header .icons a:hover {
    color: orange;
}

.arrows {
	width: 60px;
	height: 80px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: 80px;
}

.arrows path {
	stroke: white;
	fill: transparent;
	stroke-width: 5px;	
	animation: arrow 3s infinite;
	-webkit-animation: arrow 3s infinite; 
}

@keyframes arrow
{
    0% {opacity:0}
    40% {opacity:1}
    80% {opacity:0}
    100% {opacity:0}
}

.arrows path.a1 {
    stroke: white;
	animation-delay:-1.5s;
	-webkit-animation-delay:-1.5s; /* Safari 和 Chrome */
}

.arrows path.a2 {
    stroke: hsl(39, 95%, 74%);
	animation-delay:-0.75s;
	-webkit-animation-delay:-0.75s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
    stroke: rgb(255, 187, 60);
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}
  

@media only screen and (max-width: 1000px) {
    .header {
        height: 500px;
    }
    
    .header h1 {
        font-size: 3em;
        background-image: radial-gradient(rgba(0, 0, 0, .7) 0%, rgba(0,0,0,0) 100%);
    }
    
    .header h2 {
        font-size: 1.7em;
    }

    .arrows {
        bottom: 30px;
    }
}

@media only screen and (max-width: 680px) {
    .header h1 {
        font-size: 2.8em;
    }
    .header h2 {
        font-size: 1.5em;
    }
}
.about {
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #131e22;
    /* background-color: green; */
    align-items: center;

    padding: 0px 50px;
}

.about h1 {
    margin: 40px 0px 20px 0px;
    align-self: center;
    font-size: 2.5em;
}

.about .content {
    display: flex;
    justify-content: center;
    line-height: 1.6;
    /* background-color: red; */
    max-width: 1400px;
}

.about .content p {
    max-width: 1200px;
    align-self: center;
    padding-right: 100px;
    font-size: 1.2em;
}

.about .content p span {
    color: orange;
    font-size: 1.05em;
}

.about .content img {
    height: 380px;
    width: auto;
    border-radius: 20px;
    box-shadow: 0px 0px 4px orange;
}

.about2 {
    padding: 0px 50px;
    background-color: #131e22;
    /* background-color: #2b748f; */
    display: flex;
    justify-content: center;
}

.skills {
    /* background-color: green; */
    color: white;
    padding-bottom: 70px;
    width: 100%;
    height: 600px;
    max-width: 1400px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

.skills .category {
    /* background-color: red; */
    /* margin: 20px 40px; */
    text-align: left;
    max-width: 550px;
    padding: 0px 30px;
    padding-left: 0px;
}

.skills .category h3 {
    font-size: 1.7em;
    color: orange;
    /* margin: 18px 0px; */
}


.skills .category ul {
    padding-left: 0;
}

.skills .category h4 {
    color: rgb(160, 170, 180);
    font-size: 1.4em;
    /* background-color: green; */
    margin-bottom: 0px;

}


.skills .category ul li {
    list-style: none;
    text-align: left;
    font-size: 1.2em;
    padding: 5px 0px;
    color: hsl(39, 100%, 85%);
}

.skills .category ul li:nth-child(2n) {
    color: white;
}

.skills .category .subCategory ul {
    /* background-color: red; */
    display: flex;
    height: 80px;
    flex-wrap: wrap;
    flex-direction: column;
}

.skills .category .subCategory li {
    flex: 1 0 33.33%;
}

/* Override previous nth-child effect*/
.skills .category .subCategory ul li:nth-child(2n) {
    color: hsl(39, 100%, 85%);
}

.skills .category .subCategory ul li:nth-child(3n + 1) {
    color: white;
}

@media only screen and (max-width: 1400px) { 
    .skills {
        height: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}


@media only screen and (max-width: 1000px) {
    .about .content p {
        padding-right: 0px;
    }

    .about .content img {
        display: none;
    }
}

@media only screen and (max-width: 680px) {
    .about h1 {
        font-size: 2.3em;
    }
    
    .about2 {
        padding: 0px 0px 0px 50px;
    }
}
.experience {
    background-image: url(/static/media/whistler.b3b8cf0d.jpg);
    background-position: center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 60px;
}

.experience h1 {
    margin: 40px 0px 20px 0px;
    font-size: 2.5em;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
}

.experience .card {
    background-color: #131e22;
    max-width: 1100px;
    padding: 20px 40px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.experience .card h2 {
    font-size: 1.5em;
    margin: 10px 0px;
}

.experience .card a {
    text-decoration: none;
    color: skyblue;
}

.experience .card a:hover {
    color: rgb(165, 224, 248);
}

.experience .card .timerange {
    color: orange;
    font-style: italic;
    margin-top: 5px;
}

.experience .card .card-body {
    line-height: 1.6;
    font-size: 1.2em;
}

@media only screen and (max-width: 680px) {
    .experience h1 {
        font-size: 1.8em;
    }
}
.projects {
    padding: 20px 0px 30px 0px;
    margin: 0px;

    background-color: #131e22;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects h1 {
    text-align: center;
    /* background-color: green; */
    margin: 40px 0px 0px 0px;
    /* text-shadow: 0px 4px 10px #000000be; */
    font-size: 2.5em;
    color: white;
}

@media only screen and (max-width: 1000px) {   
    .projects h1 {
        margin: 20px 0px 0px 0px;
    }
}

@media only screen and (max-width: 680px) {
    .projects h1 {
        margin: 10px 0px 0px 0px;
        font-size: 1.8em;
    }
}
.projectItem {
    /* background-color: grey; */
    max-width: 1500px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.projectInfo {
    margin: 20px 40px;
    padding: 0px 0px;
}

.projectInfo h2 {
    color: orange;
}

.projectInfo p {
    font-size: 1.2em;
}

.projectInfo span {
    color: rgb(255, 213, 134);
    font-weight: 600;
}

.projectInfo a {
    color: skyblue;
    font-weight: 550;
    text-decoration: none;
}

.projectInfo a:hover {
    color: rgb(165, 224, 248);
}

.projectImage {
    margin: 20px;
    border-style: solid;
    border-width: 3px;
    border-color: white;
    border-radius: 20px;


    align-self: center;
    /* object-fit: cover; */
    height: 400px;
    /* width: auto; */
    
}

.hidden {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .projectItem {
        flex-direction: column;
    }

    .projectImage {
        display: none;
    }
}
/* @media only screen and (max-width: 680px) {

} */
.footer {
    text-align: center;
    padding: 15px 15px;
    background-color: #131e22;
    color: rgb(207, 207, 207);
}

.footer h2 {
    margin: 0px;
    font-size: 1em;
    font-weight: 400;
}

@media only screen and (max-width: 680px) {
    .footer h2 {
        font-size: 0.8em;
    }
}
.contact {
    background-image: url(/static/media/lionsgate.ed83991a.jpg);
    background-position: center;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 60px 0px 100px 0px;
    margin: 0px;
}

.contactContent {
    background-color: #131e22;

    margin: 0px 40px;
    padding: 20px;
    padding-bottom: 40px;
    border-radius: 30px;
}

.contactContent h1 {
    margin: 30px 0px 10px 0px;
    text-align: center;
    font-size: 2.5em;
    color: white;
}

.contactInfo {
    width: 100%;
    max-width: 1600px;
    display: flex;
    flex-direction: row;
    align-items: left;
    /* background-color: #1d2b30; */
}

.contactText {
    max-width: 800px;
    font-size: 1.2em;
    margin: 0px 40px;
}

.contactText a {
    text-decoration: none;
    font-weight: 550;
    color: skyblue;
}

.contactText a:hover {
    color: rgb(165, 224, 248);
}

.contactText span {
    color: orange;
}

.contactContent .dynamic1 {
    display: inline;
    color: white;
}

.contactContent .dynamic2 {
    display: none;
    color: white;
}

.contactContent ul {
    margin: 20px 50px;
}

.contactContent ul li a {
    text-decoration: none;
    color: hsl(39, 100%, 85%);
}

.contactContent ul li {
    list-style: none;
    text-align: left;
    font-size: 1.2em;
    padding: 5px 0px;
    color: hsl(39, 100%, 85%);
}



.contactContent ul li:nth-child(2n) {
    color: white;
}

@media only screen and (max-width: 1000px) {
    .contactInfo {
        flex-direction: column;
    }

    .contactContent .dynamic1 {
        display: none;
    }

    .contactContent .dynamic2 {
        display: inline;
    }

    .contactContent ul {
        padding-left: 20px;
    }
}
@media only screen and (max-width: 680px) {
    .contactContent h1 {
        font-size: 1.9em;
    }
    
    .contactContent {
        padding: 10px;
    }

    .contactContent ul li {
        font-size: 1em;
    }
}
