.about {
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #131e22;
    /* background-color: green; */
    align-items: center;

    padding: 0px 50px;
}

.about h1 {
    margin: 40px 0px 20px 0px;
    align-self: center;
    font-size: 2.5em;
}

.about .content {
    display: flex;
    justify-content: center;
    line-height: 1.6;
    /* background-color: red; */
    max-width: 1400px;
}

.about .content p {
    max-width: 1200px;
    align-self: center;
    padding-right: 100px;
    font-size: 1.2em;
}

.about .content p span {
    color: orange;
    font-size: 1.05em;
}

.about .content img {
    height: 380px;
    width: auto;
    border-radius: 20px;
    box-shadow: 0px 0px 4px orange;
}

.about2 {
    padding: 0px 50px;
    background-color: #131e22;
    /* background-color: #2b748f; */
    display: flex;
    justify-content: center;
}

.skills {
    /* background-color: green; */
    color: white;
    padding-bottom: 70px;
    width: 100%;
    height: 600px;
    max-width: 1400px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

.skills .category {
    /* background-color: red; */
    /* margin: 20px 40px; */
    text-align: left;
    max-width: 550px;
    padding: 0px 30px;
    padding-left: 0px;
}

.skills .category h3 {
    font-size: 1.7em;
    color: orange;
    /* margin: 18px 0px; */
}


.skills .category ul {
    padding-left: 0;
}

.skills .category h4 {
    color: rgb(160, 170, 180);
    font-size: 1.4em;
    /* background-color: green; */
    margin-bottom: 0px;

}


.skills .category ul li {
    list-style: none;
    text-align: left;
    font-size: 1.2em;
    padding: 5px 0px;
    color: hsl(39, 100%, 85%);
}

.skills .category ul li:nth-child(2n) {
    color: white;
}

.skills .category .subCategory ul {
    /* background-color: red; */
    display: flex;
    height: 80px;
    flex-wrap: wrap;
    flex-direction: column;
}

.skills .category .subCategory li {
    flex: 1 0 33.33%;
}

/* Override previous nth-child effect*/
.skills .category .subCategory ul li:nth-child(2n) {
    color: hsl(39, 100%, 85%);
}

.skills .category .subCategory ul li:nth-child(3n + 1) {
    color: white;
}

@media only screen and (max-width: 1400px) { 
    .skills {
        height: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}


@media only screen and (max-width: 1000px) {
    .about .content p {
        padding-right: 0px;
    }

    .about .content img {
        display: none;
    }
}

@media only screen and (max-width: 680px) {
    .about h1 {
        font-size: 2.3em;
    }
    
    .about2 {
        padding: 0px 0px 0px 50px;
    }
}