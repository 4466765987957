.projectItem {
    /* background-color: grey; */
    max-width: 1500px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.projectInfo {
    margin: 20px 40px;
    padding: 0px 0px;
}

.projectInfo h2 {
    color: orange;
}

.projectInfo p {
    font-size: 1.2em;
}

.projectInfo span {
    color: rgb(255, 213, 134);
    font-weight: 600;
}

.projectInfo a {
    color: skyblue;
    font-weight: 550;
    text-decoration: none;
}

.projectInfo a:hover {
    color: rgb(165, 224, 248);
}

.projectImage {
    margin: 20px;
    border-style: solid;
    border-width: 3px;
    border-color: white;
    border-radius: 20px;


    align-self: center;
    /* object-fit: cover; */
    height: 400px;
    /* width: auto; */
    
}

.hidden {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .projectItem {
        flex-direction: column;
    }

    .projectImage {
        display: none;
    }
}
/* @media only screen and (max-width: 680px) {

} */