.header {
    margin: 0;
    padding: 0;
    background-image: url('../../Assets/burrard_bridge_dark.jpg');
    background-size: 1920px;
    background-position: 50% 65%;
    color: white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    height: 800px;
    position: relative;
}

.header .name {
    align-self: center;
    text-align: center;
    text-shadow: 0px 2px 20px #000000;
    
}

.header h1 {
    font-size: 5em;
    margin: 0;
    /* background-image: radial-gradient(rgba(0, 0, 0, .4) 0%, rgba(0,0,0,0) 100%); */
}

.header h2 {
    font-size: 2em;
    margin: 0;
    /* background-image: radial-gradient(rgba(0, 0, 0, 1) 0%, rgba(0,0,0,0) 100%); */
}

.header .icons {
    display: flex;
    justify-content: space-between;
    align-self: center;
    /* background-image: radial-gradient(rgba(0, 0, 0, 0.450) 0%, rgba(0,0,0,0) 100%); */
}

.header .icons a {
    font-size: 1.8em;
    margin: 20px 10px;
    color: white;
}

.header .icons a:hover {
    color: orange;
}

.arrows {
	width: 60px;
	height: 80px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: 80px;
}

.arrows path {
	stroke: white;
	fill: transparent;
	stroke-width: 5px;	
	animation: arrow 3s infinite;
	-webkit-animation: arrow 3s infinite; 
}

@keyframes arrow
{
    0% {opacity:0}
    40% {opacity:1}
    80% {opacity:0}
    100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
    0% {opacity:0}
    40% {opacity:1}
    80% {opacity:0}
    100% {opacity:0}
}

.arrows path.a1 {
    stroke: white;
	animation-delay:-1.5s;
	-webkit-animation-delay:-1.5s; /* Safari 和 Chrome */
}

.arrows path.a2 {
    stroke: hsl(39, 95%, 74%);
	animation-delay:-0.75s;
	-webkit-animation-delay:-0.75s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
    stroke: rgb(255, 187, 60);
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}
  

@media only screen and (max-width: 1000px) {
    .header {
        height: 500px;
    }
    
    .header h1 {
        font-size: 3em;
        background-image: radial-gradient(rgba(0, 0, 0, .7) 0%, rgba(0,0,0,0) 100%);
    }
    
    .header h2 {
        font-size: 1.7em;
    }

    .arrows {
        bottom: 30px;
    }
}

@media only screen and (max-width: 680px) {
    .header h1 {
        font-size: 2.8em;
    }
    .header h2 {
        font-size: 1.5em;
    }
}